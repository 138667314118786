:root {
  --gray-light: rgba(255, 255, 255, 0.08);
  --gray-dark: rgba(0, 0, 0, 0.1);
  --active-bullet-dark: rgb(0, 0, 0);
  --active-bullet-light: rgb(255, 255, 255);
}

img {
  width: 100%;
}

.swiper_container {
  height: 100%;
}

.swiper_container .swiper-button-next,
.swiper_container .swiper-button-prev {
  background: #171717be;
  color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.swiper_container .swiper-button-next::after,
.swiper_container .swiper-button-prev::after {
  font-size: 18px;
}

.swiper-slide-active {
  opacity: 1;
}

/* Pagination */
.swiper-pagination {
  background-color: rgba(0, 0, 0, 0.5);
  padding: 8px 12px !important;
  width: fit-content !important;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  gap: 4px;
}

/* moving pagination */
.swiper-pagination-bullet {
  height: 2px;
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.25) !important;
  opacity: 1;
  border-radius: 0;
  transition: all 0.2s linear;
}

.swiper-pagination-bullet::before {
  content: '';
  display: block;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.swiper-pagination-bullet-active::before {
  background-color: white;
  width: 0;
  animation: progressBar 8s ease-in-out forwards;
}

@keyframes progressBar {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.paused .swiper-pagination-bullet-active::before {
  animation-play-state: paused;
  animation: none;
  background: rgba(255, 255, 255, 0.25) !important;
}

.paused .swiper-pagination-bullet-active {
  background-color: white !important;
  width: 10px !important;
}

.paused .swiper-pagination-bullet {
  background: rgba(255, 255, 255, 0.25) !important;
}

.paused .swiper-pagination-bullet-active {
  background: white !important;
}

.swiper_container > .swiper-pagination-bullets {
  left: 0;
  right: 0;
  width: 100%;
  bottom: 20px;
  width: max-content;
  margin: 0 auto;
}

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -webkit-appearance: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  width: 40px !important;
  background: rgba(255, 255, 255, 0.25) !important;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  position: relative;
  overflow: hidden;
  /* background: var(--gray-light); */
  background: rgba(255, 255, 255, 0.25) !important;
  opacity: 1;
  border-radius: 50px !important;
}

.swiper-pagination-bullet::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 768px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  .swiper-pagination::after {
    display: none;
  }
}
