:root {
  --gray-light: rgba(255, 255, 255, 0.08);
  --gray-dark: rgba(0, 0, 0, 0.1);
  --active-bullet-dark: rgb(0, 0, 0);
  --active-bullet-light: rgb(255, 255, 255);
}

.swiper_container1 {
  width: 100%;
}

.swiper_container1 .swiper-slide {
  max-width: 100%;
  height: 750px;
  touch-action: manipulation;
}

.swiper_container1 .swiper-button-next,
.swiper_container1 .swiper-button-prev {
  /* background: #171717be; */
  background: transparent;
  color: white;
  border: 2px solid white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}

.swiper_container1 .swiper-button-next:hover,
.swiper_container1 .swiper-button-prev:hover {
  background: white;
  color: black;
  transition: 0.1s;
}

.swiper_container1 .swiper-button-next::after,
.swiper_container1 .swiper-button-prev::after {
  font-size: 17px;
  font-weight: 1000;
}

.swiper_container1 .swiper-button-next::after {
  margin-left: 3px;
  margin-top: 1px;
}
.swiper_container1 .swiper-button-prev::after {
  margin-right: 2px;
  margin-top: 1px;
}

.swiper_container1 .swiper-slide-active {
  display: block;
  opacity: 1;
}

.swiper_container1 .swiper-pagination-bullet-active::before {
  background-color: #fff;
}

.swiper_container1 .swiper-pagination {
  margin: 0 auto;
  border-radius: 20px;
}

.swiper_container1 .swiper-pagination-bullet {
  /* background-color: var(--gray-light); */
  background: rgba(255, 255, 255, 0.25) !important;
  border-radius: 50px !important;
}

.swiper_container1 > .swiper-pagination-bullets {
  left: 0;
  right: 0;
  bottom: 20px !important;
  width: max-content;
}

@media screen and (max-width: 768px) {
  .swiper_container1 .swiper-slide {
    max-width: 100%;
    height: 600px;
  }
  .swiper_container1 .swiper-button-next,
  .swiper_container1 .swiper-button-prev {
    opacity: 0;
  }
}

.swiper_container2 {
  width: 100%;
}

.swiper_container2 .swiper-slide {
  max-width: 100%;
  height: 750px;
  touch-action: manipulation;
}

.swiper_container2 .swiper-button-next,
.swiper_container2 .swiper-button-prev {
  /* background: #171717be; */
  background: transparent;
  color: white;
  border: 2px solid white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}

.swiper_container2 .swiper-button-next:hover,
.swiper_container2 .swiper-button-prev:hover {
  background: white;
  color: black;
  transition: 0.1s;
}

.swiper_container2 .swiper-button-next::after,
.swiper_container2 .swiper-button-prev::after {
  font-size: 17px;
  font-weight: 1000;
}

.swiper_container2 .swiper-button-next::after {
  margin-left: 3px;
  margin-top: 1px;
}
.swiper_container2 .swiper-button-prev::after {
  margin-right: 2px;
  margin-top: 1px;
}

.swiper_container2 .swiper-slide-active {
  display: block;
  opacity: 1;
}

.swiper_container2 .swiper-pagination-bullet-active::before {
  background-color: #fff;
}

.swiper_container2 .swiper-pagination {
  margin: 0 auto;
  border-radius: 20px;
}

.swiper_container2 .swiper-pagination-bullet {
  /* background-color: var(--gray-light); */
  background: rgba(255, 255, 255, 0.25) !important;
  border-radius: 50px !important;
}

.swiper_container2 > .swiper-pagination-bullets {
  left: 0;
  right: 0;
  bottom: 20px !important;
  width: max-content;
}

@media screen and (max-width: 768px) {
  .swiper_container2 .swiper-slide {
    max-width: 100%;
    height: 600px;
  }
  .swiper_container2 .swiper-button-next,
  .swiper_container2 .swiper-button-prev {
    margin: 0 10px;
  }
}
