.link {
  position: relative;
}

.link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  animation-name: underline;
  animation-duration: 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes underline {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slideComponentWrapper .carousel-inner {
  height: 100%;
}

.slideComponentWrapper .carousel-indicators {
  display: none;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* transition: transform 0.37s ease-in-out; */
  /* transition-delay: 50ms; */

  transition:
    transform 0.39s ease-in-out,
    -webkit-transform 0.39s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

.slidein-rtl {
  /* transform: translateX(200%);
  transition:
    transform 0.4s ease-in-out cubic-bezier(0.6, 0, 0.4, 1),
    -webkit-transform 0.4s ease-in-out cubic-bezier(0.6, 0, 0.4, 1);
  opacity: 0; */

  transform: translateX(30%);
  transition:
    transform 0.19s ease-out,
    -webkit-transform 0.19s ease-out;
  opacity: 0;
}
.slidein-ltr {
  /* transform: translateX(-200%);
  transition:
    transform 0.4s ease-in-out cubic-bezier(0.6, 0, 0.4, 1),
    -webkit-transform 0.4s ease-in-out cubic-bezier(0.6, 0, 0.4, 1);
  opacity: 0; */

  transform: translateX(-30%);
  transition:
    transform 0.19s ease-out,
    -webkit-transform 0.19s ease-out;
  opacity: 0;
}

@media (max-width: 768px) {
  .slidein-rtl,
  .slidein-ltr {
    transform: translateX(0);
    -webkit-transform: translateX(0);
    /* transition:
      transform 0.19s ease-out,
      -webkit-transform 0.19s ease-out; */
    transition: none;
    opacity: 1;
  }
  .carousel-item {
    transition:
      transform 0.3s ease-in-out,
      -webkit-transform 0.3s ease-in-out;
    /* transition: none; */
  }
}

.active .slider-content {
  transform: translateX(0);
  opacity: 1;
}
