.flare-button {
  position: relative;
  overflow: hidden;
}

.flare-button::before {
  content: '';
  position: absolute;
  top: 0;
  right: -130%;
  width: 130%;
  height: 100%;
  filter: blur(30px);
  background: linear-gradient(
    to left,
    rgba(255, 255, 255, 0.592),
    rgba(255, 255, 255, 0.367)
  );
  transition: right 0.5s ease;
}

.flare-button:hover::before {
  right: 100%;
}

@media (min-width: 640px) {
  .flare-button.black:hover {
    background-color: #646464 !important;
    border-color: #646464 !important;
  }
  .flare-button.white:hover {
    background-color: #c8c8c8 !important;
    border-color: #c8c8c8 !important;
  }
}
